<template>
  <div>
    <vx-card class="mt-8">
      <div class="card-title">
          <h2 v-if="!isReseller">Available Documentation and Support</h2>
          <h2 v-if="isReseller">Available Support</h2>
      </div>
      <div class="report-list general-report">
        <ul>
          <li>
            <feather-icon icon="CheckIcon"></feather-icon>
            <div v-if="!isReseller">
              <h3><img src="../../../src/assets/images/knowledge.png">Knowledge Base</h3>
              <p>We have additional resources available to ensure you receive the information you need. Below is a link to our knowledge base and ticketing system. If you do not find the information you need to get the most out of Visual Visitor, please feel free to submit a ticket.</p>
              <div class="base-btn"><vs-button type="filled" class="small-btn bg-actionbutton"> <a href="https://support.visualvisitor.com/" target="_blank" class="text-white">Open Knowledge base</a></vs-button></div>
            </div>
            <div v-if="isReseller">
              <h3><img src="../../../src/assets/images/knowledge.png">Email Support</h3>
              <p>Have questions? Need Help? Email support at <a :href="'mailto:'+resellerSupportEmail" class="ng-binding">{{resellerSupportEmail}}</a></p>
              <div class="base-btn"><vs-button type="filled" class="small-btn bg-actionbutton"> <a :href="'mailto:'+resellerSupportEmail" class="text-white">Email Support</a></vs-button></div>
            </div>
          </li>              
        </ul>
      </div>
    </vx-card>  
  </div>
</template>

<script>
import VxCard from "@/components/vx-card/VxCard";


export default {
  components: {
    VxCard,
  },
  data() {
    return {
      resellerSupportEmail: null,
      isReseller:  null
    }
  },
  created() {
    let uid = this.$route.params.id
    let VVtoken = localStorage.getItem("VVtoken");
    let token = JSON.parse(VVtoken);
    this.isReseller = token[uid].isReseller
    this.getOrganizationSupportEmail();
  },
  methods: {
    getOrganizationSupportEmail(){
      this.$vs.loading();
      this.axios.get('/ws/Organization/GetOrganizationSupportEmail').then(response => {
        let data = response.data;
        this.resellerSupportEmail = data
        this.$vs.loading.close();
      }).catch(e => {
        this.$vs.loading.close();
        this.showError(e);
      });
    }  
  }
};
</script>
